import { useRef, useState } from "react";
import nhacnen from "./sounds/nhacnen.mp3";

export const MusicNote = () => {
  const [playing, setPlaying] = useState(false);
  const audioRef = useRef(null);
  const buttonRef = useRef(null);

  const toggleMusic = () => {
    if (!audioRef.current.paused) {
      audioRef.current.pause();
      setPlaying(false);
    } else {
      audioRef.current.muted = false;
      audioRef.current.play();
      setPlaying(true);
    }
  };

  const playMusic = () => {
    console.log("herererererer");
    audioRef.current.muted = false;
    audioRef.current.play();
    setPlaying(true);
    // document.body.removeEventListener("mousedown", playMusic);
    document.body.removeEventListener("touchmove", playMusic);
  };

  // useEffect(() => {
  //   // document.body.addEventListener("mousedown", playMusic);
  //   document.body.addEventListener("touchmove", playMusic);
  //   // setTimeout(() => {
  //   //   audioRef.current.muted = false;
  //   //   audioRef.current
  //   //     .play()
  //   //     .then(() => setPlaying(true))
  //   //     .catch((_error) => {
  //   //       setTimeout(() => buttonRef.current.click(), 1000);
  //   //     });
  //   // }, 2000);
  //   return () => {
  //     // document.body.removeEventListener("mousedown", playMusic);
  //     document.body.removeEventListener("touchmove", playMusic);
  //   };
  // }, []);

  return (
    <div
      style={{
        position: "fixed",
        bottom: "32px",
        right: 32,
      }}>
      <div>
        <button
          style={{
            width: "54px",
            height: "54px",
            animation: playing ? "spin 4s linear infinite" : "none",
            borderRadius: "50%",
            fontSize: 32,
            lineHeight: "32px",
            background: "#ffffff33",
            color: "black",
          }}
          ref={buttonRef}
          onClick={toggleMusic}>
          ♪
        </button>
        <audio style={{ display: "none" }} ref={audioRef} id="audio" controls muted={false} src={nhacnen} />
      </div>
    </div>
  );
};

const MusicBox = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="music-box-wrapper" onClick={() => setIsOpen(true)}>
      <div id="music" className={`music-box ${isOpen ? "open" : "close"}`}>
        {/* <input type="checkbox" id="music" /> */}
        <div className="music"></div>
        <div className="box-inside">
          <div className="paper"></div>
          <div className="inner"></div>
        </div>
        <div className="box"></div>
        <div className="shadow"></div>
      </div>
    </div>
  );
};

export default MusicBox;
