import { useState, useEffect, useRef } from "react";
import Messages from "./Messages";
import { addMessage, runSub } from "./firebase";

const Wishing = () => {
  const [isSent, setIsSent] = useState(false);
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const nameRef = useRef(null);
  const messageRef = useRef(null);

  const send = () => {
    addMessage({ name, message }).then(() => {
      setName("");
      setMessage("");
      // fetchMessages();
      setIsSent(true);
      nameRef.current.value = "";
      messageRef.current.value = "";
    });
  };

  // const fetchMessages = async () => {
  //   const res = await getMessages();
  //   console.log(res);
  //   setMessages(res.sort((a, b) => b.at - a.at));
  // };

  // useEffect(() => {
  //   fetchMessages();
  // }, []);

  useEffect(() => {
    // real-time update messages
    const unsub = runSub(setMessages);
    return () => {
      unsub && unsub();
    };
  }, []);

  return (
    <div style={{ background: "#f2f4f4", padding: "32px 0" }}>
      <div style={{ textAlign: "center", fontSize: 40 }}>Wishing well</div>
      {!isSent ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "auto",
            padding: 16,
            boxSizing: "border-box",
            background: "#f2e9dc",
          }}>
          <div>Your name</div>
          <input
            ref={nameRef}
            style={{
              marginTop: 16,
              height: 40,
              background: "#fffbf3",
              borderRadius: 6,
              outline: "none",
              border: "none",
              padding: "4px 8px",
              fontFamily: "sans-serif",
              color: "black",
            }}
            onChange={(e) => setName(e.target.value)}
            name="name"></input>
          <div style={{ marginTop: 32 }}>Message</div>
          <textarea
            ref={messageRef}
            style={{
              marginTop: 16,
              padding: "4px 8px",
              background: "#fffbf3",
              borderRadius: 6,
              outline: "none",
              border: "none",
              fontFamily: "sans-serif",
              color: "black",
            }}
            onChange={(e) => setMessage(e.target.value)}
            name="message"
            rows="4"
            cols="50"
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}>
            <button
              disabled={!name || !message}
              style={{
                marginTop: 16,
                height: 40,
                background: "#d1b681",
                borderRadius: 20,
                outline: "none",
                border: "none",
                width: 90,
                color: "white",
              }}
              onClick={send}>
              Send
            </button>
          </div>
        </div>
      ) : (
        <div
          style={{
            padding: 32,
            fontSize: 24,
            fontFamily: "Ephesis",
            textAlign: "center",
          }}>
          Cảm ơn bạn đã gửi lời chúc tới mình. <br />
          Hy vọng mọi điều tuyệt với nhất sẽ đến với bạn!
          <br /> Mãi iuuuuuuu!
        </div>
      )}

      <Messages messages={messages} />
    </div>
  );
};

export default Wishing;
