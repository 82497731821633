// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, collection, query, where, onSnapshot, orderBy } from "firebase/firestore";
import {
  getFirestore as getFirestoreLite,
  collection as collectionLite,
  getDocs,
  addDoc,
  getDoc,
  setDoc,
  doc,
} from "firebase/firestore/lite";
// import { doc, addDoc } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBAJQTLkm4KJ--8bQbsTtVg2xTy2ukydMo",
  authDomain: "loichucthiepcuoi.firebaseapp.com",
  projectId: "loichucthiepcuoi",
  storageBucket: "loichucthiepcuoi.appspot.com",
  messagingSenderId: "908285832582",
  appId: "1:908285832582:web:1eada80f1e8ada1135d007",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const dbLite = getFirestoreLite(app);

async function getMessages() {
  const loichucCol = collection(dbLite, "loichuc");
  const loiChucSnapshot = await getDocs(loichucCol);
  const messages = loiChucSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  return messages;
}

async function addMessage({ name, message }) {
  try {
    const docRef = await addDoc(collectionLite(dbLite, "loichuc"), {
      name: name,
      message: message,
      at: Date.now(),
    });
    return docRef.id;
  } catch (e) {
    console.log("bug", e);
  }
}

async function addHeart(id) {
  const docRef = doc(dbLite, "loichuc", id);
  const docSnap = await getDoc(docRef);
  const old = docSnap.data();
  await setDoc(docRef, { heart: (old.heart || 0) + 1 }, { merge: true });
}

const getQuery = () => {
  try {
    return query(collection(db, "loichuc"), where("at", ">", 0), orderBy("at", "desc"));
  } catch (e) {
    console.log("eeeee", e);
    return null;
  }
};

const runSub = (callback = () => {}) => {
  const q = getQuery();
  try {
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const messages = [];
      querySnapshot.forEach((doc) => {
        messages.push({ id: doc.id, ...doc.data() });
      });
      callback(messages);
    });

    return unsubscribe;
  } catch (e) {
    console.log("errrer", e);
    return null;
  }
};

export { getMessages, addMessage, addHeart, runSub };
