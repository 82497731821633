import "animate.css/animate.compat.css";
import Zoom from "react-reveal/Zoom";
import Flash from "react-reveal/Flash";
import Fade from "react-reveal/Fade";
import Jump from "react-reveal/Jump";
import Pulse from "react-reveal/Pulse";
import Jello from "react-reveal/Jello";
import Slide from "react-reveal/Slide";
import Reveal from "react-reveal/Reveal";
import Swing from "react-reveal/Swing";
import LightSpeed from "react-reveal/LightSpeed";
import Tada from "react-reveal/Tada";
import bg0 from "./images/bg0.jpg";
import bg1 from "./images/bg1.jpg";
import chure from "./images/chure.jpg";
import codau from "./images/codau.jpg";

import page5image1 from "./images/page5image1.jpg";
import page5image2 from "./images/page5image2.jpg";
import page5image3 from "./images/page5image3.jpg";
import page5image4 from "./images/page5image4.jpg";

import anh_d1 from "./images/slide/anh_d1.jpg";
import anh_d2 from "./images/slide/anh_d2.jpg";
import anh_d3 from "./images/slide/anh_d3.jpg";
import anh_d4 from "./images/slide/anh_d4.jpg";
import anh_d5 from "./images/slide/anh_d5.jpg";
import anh_d6 from "./images/slide/anh_d6.jpg";
import anh_d7 from "./images/slide/anh_d7.jpg";
import anh_n1 from "./images/slide/anh_n1.jpg";
import anh_n2 from "./images/slide/anh_n2.jpg";
import anh_n3 from "./images/slide/anh_n3.jpg";
import anh_n4 from "./images/slide/anh_n4.jpg";
import anh_n5 from "./images/slide/anh_n5.jpg";
import anh_n6 from "./images/slide/anh_n6.jpg";

import thankyou from "./images/thankyou.png";

import logo from "./images/logo.png";

import { useEffect, useState, useRef } from "react";
import MusicBox, { MusicNote } from "./MusicBox";
import Messages from "./Messages";
import Wishing from "./Wishing";

const AnimatedContent = ({ children, animation = "zoom" }) => {
  switch (animation) {
    case "zoom":
      return <Zoom animateIn="fadeIn">{children}</Zoom>;
    case "flash":
      return <Flash animateIn="fadeIn">{children}</Flash>;
    case "fade":
      return <Fade animateIn="fadeIn">{children}</Fade>;
    case "jump":
      return <Jump animateIn="fadeIn">{children}</Jump>;
    case "pulse":
      return <Pulse animateIn="fadeIn">{children}</Pulse>;
    case "jello":
      return <Jello animateIn="fadeIn">{children}</Jello>;
    case "lightSpeed":
      return <LightSpeed animateIn="fadeIn">{children}</LightSpeed>;
    case "slide":
      return <Slide animateIn="fadeIn">{children}</Slide>;
    case "swing":
      return <Swing animateIn="fadeIn">{children}</Swing>;
    case "tada":
      return <Tada animateIn="fadeIn">{children}</Tada>;
    case "reveal":
      return <Reveal animateIn="fadeIn">{children}</Reveal>;
    case "none":
      return children;
    default:
      return <Zoom animateIn="fadeIn">{children}</Zoom>;
  }
};

const Section = ({ children, animation = "zoom", style = {} }) => {
  return (
    <section className={`section with-bg `} style={style}>
      <AnimatedContent animation={animation}>{children}</AnimatedContent>
    </section>
  );
};

let interval;

function App() {
  const timer = () => {
    let countDownDate = new Date(
      document.getElementById("weddingCountdown").getAttribute("data-time").replace(" ", "T")
    ).getTime();

    interval = setInterval(() => {
      let distance = Math.abs(countDownDate - new Date().getTime());

      document.getElementById("date").innerText = Math.floor(distance / (1000 * 60 * 60 * 24));
      document.getElementById("hour").innerText = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      document.getElementById("minute").innerText = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      document.getElementById("second").innerText = Math.floor((distance % (1000 * 60)) / 1000);
    }, 1000);
  };

  useEffect(() => {
    timer();
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <Section
        key={0}
        animation={"fade"}
        style={{
          backgroundImage: `url(${bg0}`,
          position: "relative",
          backgroundColor: "#534937b5",
        }}>
        <div
          style={{
            position: "absolute",
            bottom: "8vh",
            color: "white",
            width: "100vw",
            textAlign: "center",
            fontWeight: "bold",
          }}>
          <div style={{ fontSize: "12vw", color: "#F5BC58" }}>SAVE THE DATE</div>
          <div>Cong Dat & Ngoc Nga</div>
        </div>
      </Section>

      <Section
        key={1}
        className="section with-bg"
        style={{
          backgroundImage: `url(${bg1}`,
          backgroundColor: "#615c53b5",
        }}>
        <div
          style={{
            position: "absolute",
            top: "12vh",
            color: "white",
            width: "100vw",
            textAlign: "center",
          }}>
          <div
            style={{
              paddingTop: 32,
              fontSize: "18vw",
              // fontFamily: "Halimun",
              fontWeight: "bold",
              background: "#0000006b",
              color: "#f5bc58",
            }}>
            Welcome
          </div>
          <div
            style={{
              letterSpacing: 3,
              background: "#0000006b",
              paddingBottom: 16,
              fontSize: "7vw",
              fontFamily: "Ephesis",
            }}>
            We’re glad you’re here
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "5vh",
            color: "white",
            width: "100vw",
            textAlign: "center",
            fontSize: "5vw",
            color: "#f5bc58",
          }}>
          {/* <div style={{ fontSize: 32 }}>Cong Dat & Ngoc Nga</div> */}
          <div>June 2024</div>
        </div>
      </Section>

      <Section
        key={2}
        // animation="step"
        style={{
          backgroundImage: `url(${chure}`,
          // backgroundColor: "#615c53b5",
        }}>
        <div
          style={{
            position: "absolute",
            bottom: "4vh",
            color: "white",
            padding: "0 16px",
          }}>
          <div style={{ paddingTop: "100px", fontSize: "11vw", fontFamily: "Halimun", marginBottom: "-38px" }}>
            Groom
          </div>
          <div style={{ fontSize: "18vw", color: "#F5BC58" }}>CONG DAT</div>
          <div
            style={{
              fontFamily: "Quicksand",
              opacity: 0.5,
              fontSize: "12px",
              padding: "0 16px",
            }}>
            Là một người cởi mở, thân thiện, thuộc tuýp người hướng ngoại, luôn coi trọng tình cảm và yêu thương gia
            đình.
          </div>
        </div>
      </Section>

      <Section
        key={3}
        style={{
          backgroundImage: `url(${codau}`,
          // backgroundColor: "#615c53b5",
        }}>
        <div
          style={{
            position: "absolute",
            bottom: "4vh",
            color: "#5b4228",
            padding: "0 16px",
          }}>
          <div
            style={{
              paddingTop: "100px",
              fontSize: "11vw",
              fontFamily: "Halimun",
              // textAlign: "center",
              marginBottom: "-34px",
            }}>
            Bride
          </div>
          <div style={{ fontSize: "17vw", color: "#b28d70" }}>NGOC NGA</div>
          <div
            style={{
              fontFamily: "Quicksand",
              opacity: 0.5,
              fontSize: "12px",
              padding: "0 8px",
            }}>
            Là một người nói nhiều nhưng sống nội tâm, thích phim hoạt hình và yêu hoa, ước mơ là mở một tiệm hoa nhỏ
            xinh.
          </div>
        </div>
      </Section>

      <Section
        key={4}
        animation="none"
        style={{
          background: "#f2f4f4",
        }}>
        <div style={{ position: "absolute", color: "black", right: 0, textAlign: "right", padding: "32px" }}>
          <div style={{ fontSize: "11vw" }}>WEDDING</div>
          <div style={{ fontSize: "11vw" }}>COUNTDOWN</div>

          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "6vw",
                marginTop: 32,
              }}
              data-time="2024-06-02 00:00:00"
              id="weddingCountdown">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "8vw",
                  fontFamily: "Aldrich",
                }}>
                <h2
                  style={{
                    fontFamily: "Aldrich",
                  }}
                  id="date">
                  0
                </h2>
                <small style={{ fontSize: "4vw" }}>Days</small>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "8vw",
                  fontFamily: "Aldrich",
                }}>
                <h2
                  style={{
                    fontFamily: "Aldrich",
                  }}
                  id="hour">
                  0
                </h2>
                <small style={{ fontSize: "4vw" }}>Hours</small>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "8vw",
                  fontFamily: "Aldrich",
                }}>
                <h2
                  style={{
                    fontFamily: "Aldrich",
                  }}
                  id="minute">
                  0
                </h2>
                <small style={{ fontSize: "4vw" }}>Minutes</small>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "8vw",
                  fontFamily: "Aldrich",
                }}>
                <h2
                  style={{
                    fontFamily: "Aldrich",
                  }}
                  id="second">
                  0
                </h2>
                <small style={{ fontSize: "4vw" }}>Seconds</small>
              </div>
            </div>
          </div>

          <div style={{ fontSize: "4vw", marginTop: 16 }}>Until we officially become Mr & Mrs</div>
        </div>

        <div
          style={{
            position: "absolute",
            bottom: "4vh",
            color: "#5b4228",
            padding: "0 16px",
          }}>
          <div
            style={{
              position: "absolute",
              width: "83vw",
              height: "29vh",
              bottom: "34vh",
              borderRadius: 10,
              overflow: "hidden",
              left: "16vw",
            }}>
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "left",
              }}
              src={page5image3}></img>
          </div>

          <div
            style={{
              position: "absolute",
              width: "48vw",
              height: "30vh",
              bottom: "16vh",
              left: "-2vw",
              borderRadius: 10,
              overflow: "hidden",
              border: "8px solid white",
            }}>
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              src={page5image1}></img>
          </div>
          <div
            style={{
              position: "absolute",
              width: "40vw",
              height: "50vw",
              objectFit: "cover",
              bottom: "16vh",
              left: "48vw",
              overflow: "hidden",
              borderRadius: 10,
              border: "8px solid white",
            }}>
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "-31vw 0vh",
              }}
              src={page5image2}></img>
          </div>
          <div
            style={{
              position: "absolute",
              width: "54vw",
              height: "18vh",
              objectFit: "cover",
              bottom: "0vh",
              left: "28vw",
              overflow: "hidden",
              borderRadius: 10,
              border: "8px solid white",
            }}>
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              src={page5image4}></img>
          </div>
        </div>
      </Section>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <div style={{ width: "60%", borderTop: "1px dashed black" }}></div>
      </div>

      <Section style={{ background: "#f2f4f4" }} animation="none">
        <div
          style={{
            display: "flex",
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <div>
            <img style={{ width: "50vw" }} src={logo}></img>
          </div>
          <div style={{ fontSize: "8vw", fontFamily: "Ephesis" }}>Save The Date</div>
          <div style={{ fontFamily: "serif" }}>FOR THE WEDDING CELEBRATION OF </div>
          <div style={{ display: "flex", gap: 8, alignItems: "center", justifyContent: "center", paddingTop: 32 }}>
            <div style={{ fontSize: "8vw" }}>CONG DAT</div>
            <div style={{ fontFamily: "Halimun" }}>and</div>
            <div style={{ fontSize: "8vw" }}>NGOC NGA</div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: 32,
              width: "100%",
            }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                alignItems: "flex-end",
                padding: "0 16px",
              }}>
              <div style={{ fontFamily: "Anton" }}>TIỆC NHÀ GÁI</div>
              <div style={{ fontFamily: "serif" }}>Saturday</div>
              <div style={{ fontFamily: "serif" }}>17h30</div>
            </div>
            <div
              style={{
                borderLeft: "1px solid black",
                borderRight: "1px solid black",
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                padding: 16,
              }}>
              <div style={{ fontFamily: "Yeseva One" }}>JUNE</div>
              <div style={{ fontFamily: "Yeseva One", fontSize: "9vw" }}>01</div>
              <div style={{ fontFamily: "Yeseva One" }}>2024</div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                padding: "0 16px",
              }}>
              <div
                style={{
                  textTransform: "uppercase",
                  fontFamily: "Anton",
                }}>
                KS. Hải Âu
              </div>
              <div style={{ fontFamily: "serif" }}>Tp.Quy Nhơn</div>
              <div style={{ fontFamily: "serif" }}>Bình Định</div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: 32,
              width: "100%",
            }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                alignItems: "flex-end",
                padding: "0 16px",
              }}>
              <div style={{ fontFamily: "Anton" }}>TIỆC NHÀ TRAI</div>
              <div style={{ fontFamily: "serif" }}>Sunday</div>
              <div style={{ fontFamily: "serif" }}>17h30</div>
            </div>
            <div
              style={{
                borderLeft: "1px solid black",
                borderRight: "1px solid black",
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                padding: 16,
              }}>
              <div style={{ fontFamily: "Yeseva One" }}>JUNE</div>
              <div style={{ fontFamily: "Yeseva One", fontSize: "8vw" }}>02</div>
              <div style={{ fontFamily: "Yeseva One" }}>2024</div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                padding: "0 16px",
              }}>
              <div
                style={{
                  textTransform: "uppercase",
                  fontFamily: "Anton",
                }}>
                NH. Sông Xanh
              </div>
              <div style={{ fontFamily: "serif" }}>tt.Diêu Trì</div>
              <div style={{ fontFamily: "serif" }}>Bình Định</div>
            </div>
          </div>
        </div>
      </Section>

      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <div style={{ width: "60%", borderTop: "1px dashed black" }}></div>
      </div>

      <div style={{ background: "#f2f4f4" }} animation="none">
        <div className="container p-0 " style={{ borderRadius: 0 }}>
          <div className="card-body ">
            <h1
              className="font-esthetic text-center py-3"
              data-aos="fade-down"
              data-aos-duration="1500"
              style={{
                fontSize: 40,
              }}>
              Gallery
            </h1>

            <div
              id="carousel-foto-satu"
              data-aos="fade-up"
              data-aos-duration="1500"
              className="carousel slide"
              data-bs-ride="carousel">
              <div className="carousel-indicators" style={{ margin: 0 }}>
                {[anh_n1, anh_n2, anh_n3, anh_n4, anh_n5, anh_n6].map((a, index) => (
                  <button
                    style={{ width: 10 }}
                    key={index}
                    type="button"
                    data-bs-target="#carousel-foto-satu"
                    data-bs-slide-to={index}
                    className="active"
                    aria-current="true"
                    aria-label={`Slide ${index + 1}`}></button>
                ))}
              </div>

              <div className="carousel-inner ">
                {[anh_n1, anh_n2, anh_n3, anh_n4, anh_n5, anh_n6].map((a, index) => (
                  <div
                    key={index}
                    className={`carousel-item ${index === 0 && "active"}`}
                    style={{ pointerEvents: "none" }}>
                    <img src={a} alt="gambar 4" className="d-block w-100" onclick="util.modal(this)" />
                  </div>
                ))}
              </div>

              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carousel-foto-satu"
                data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>

              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carousel-foto-satu"
                data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>

            <div
              id="carousel-foto-dua"
              data-aos="fade-up"
              data-aos-duration="1500"
              className="carousel slide mt-4"
              data-bs-ride="carousel">
              <div className="carousel-indicators" style={{ margin: 0 }}>
                {[anh_d1, anh_d2, anh_d3, anh_d4, anh_d5, anh_d6, anh_d7].map((a, index) => (
                  <button
                    style={{ width: 10 }}
                    key={index}
                    type="button"
                    data-bs-target="#carousel-foto-dua"
                    data-bs-slide-to={index}
                    className="active"
                    aria-current="true"
                    aria-label={`Slide ${index + 1}`}></button>
                ))}
              </div>

              <div className="carousel-inner ">
                {[anh_d1, anh_d2, anh_d3, anh_d4, anh_d5, anh_d6, anh_d7].map((a, index) => (
                  <div
                    key={index}
                    className={`carousel-item ${index === 0 && "active"} `}
                    style={{ pointerEvents: "none" }}>
                    <img src={a} alt="gambar 4" className="d-block w-100" onclick="util.modal(this)" />
                  </div>
                ))}
              </div>

              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carousel-foto-dua"
                data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>

              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carousel-foto-dua"
                data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <Wishing />

      {/* <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <div style={{ width: "60%", borderTop: "1px dashed black" }}></div>
      </div> */}

      <div>
        <img
          style={{
            objectFit: "cover",
            width: "100vw",
          }}
          src={thankyou}
          alt="thank you"></img>
      </div>

      {/* <div style={{ marginTop: 32, width: "100%", display: "flex", justifyContent: "center" }}>
        <div style={{ width: "60%", borderTop: "1px dashed black" }}></div>
      </div> */}

      <div style={{ background: "#f2f4f4", padding: "56px 32px 64px 32px" }}>
        {/* <div style={{ textAlign: "center", fontSize: 56, fontFamily: "Ephesis" }}>Thank you</div>
        <div style={{ textAlign: "center", fontSize: 32, fontFamily: "Ephesis" }}>
          Your presence is a happiness and an honor for our family.
        </div>
        <div style={{ textAlign: "center", fontSize: 32, fontFamily: "Ephesis" }}>Sincerely, thank you. </div> */}
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          Cong Dat
          <span
            style={{
              color: "red",
              padding: "0 8px",
            }}>
            ♥
          </span>
          Ngoc Nga
        </div>
      </div>

      {/* <div className="modal fade" id="modal-image" tabindex="-1" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-body p-0">
              <div className="d-flex justify-content-center align-items-center" style="height: 100%;">
                <img src="./assets/images/bg0.jpg" className="w-100" alt="foto" id="show-modal-image" />
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <MusicNote />

      {/* <MusicBox /> */}
    </>
  );
}

export default App;
