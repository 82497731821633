import { addHeart } from "./firebase";

const Messages = ({ messages }) => {
  if (!messages || messages.length === 0) return <></>;
  return (
    <>
      <div style={{ padding: "0 32px", marginTop: 32, fontSize: 32 }}>The golden words</div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          // background: "#f0e3ca",
          maxHeight: 400,
          overflow: "auto",
          minHeight: 100,
          margin: 16,
          // borderRadius: 6,
          // border: "1px solid #99713b",
          padding: "8px 0",
          gap: 16,
        }}>
        <div>
          {messages.map((m, index) => (
            <Item key={m.id} id={m.id} name={m.name} message={m.message} isOdd={index % 2 === 0} heart={m.heart} />
          ))}
        </div>
      </div>
    </>
  );
};

const Item = ({ id, name, message, heart }) => {
  return (
    <div
      style={{
        // background: isOdd ? "#fff1d8" : "#f0e3ca",
        borderRadius: "4px",
        // overflow: "hidden",
        boxShadow: "0px 4px 4px #00000020",
        margin: "0px 8px 16px 8px",
      }}>
      <div style={{ fontWeight: "bold", fontFamily: "Quicksan", padding: "4px 12px 0 12px", background: "#e7ded4" }}>
        {name}
      </div>
      <div
        style={{ position: "relative", fontFamily: "Quicksan", padding: "8px 12px 12px 12px", background: "#fffbf3" }}>
        {message}

        <div
          style={{
            position: "absolute",
            right: 8,
            bottom: -12,
            display: "flex",
            gap: 4,
            alignItems: "center",
            justifyContent: "flex-end",
          }}>
          {heart && heart > 0 && (
            <div
              style={{
                background: "white",
                borderRadius: 16,
                padding: "0 8px",
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}>
              <span style={{ color: "red" }}>♥</span>
              <span style={{ fontFamily: "sans-serif" }}>{heart}</span>
            </div>
          )}
          <div
            onClick={() => addHeart(id)}
            style={{
              width: 28,
              height: 28,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "white",
              borderRadius: 16,
            }}>
            ♡
          </div>
        </div>
      </div>
    </div>
  );
};

export default Messages;
